import React from "react";

import styled from "styled-components/macro";

import { NavLink, useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import NumberFormat from "react-number-format";

import {
  Box as MuiBox,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";

import * as Icons from "@material-ui/icons";

import { spacing } from "@material-ui/system";

import { BpLots } from "../../services";

const Box = styled(MuiBox)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const PageSize = 500;

export const LotsIndexPage = () => {
  const [page, setPage] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [summary, setSummary] = React.useState({});
  const { projectId } = useParams();

  React.useEffect(() => {
    (async () => {
      const resList = await BpLots.list(projectId, page, 500);

      const statuses = resList.data.rows.reduce((acc, row) => {
        acc[row.preReservationStatus] =
          (acc[row.preReservationStatus] || 0) + 1;
        acc[row.status] = (acc[row.status] || 0) + 1;
        return acc;
      }, {});
      setSummary(statuses);

      setData(resList.data);
    })();
  }, [page]);

  const downloadCSV = () => {
    // Convert the data array into a CSV string
    const headers = Object.keys(data?.rows?.[0]);
    let csvString = [
      headers,
      ...data?.rows?.map((row) =>
        headers.reduce((acc, header) => acc.concat([row[header]]), [])
      ), // Map your data fields accordingly
    ]
      .map((row) => row.join(","))
      .join("\n");

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: "text/csv" });

    // Generate a download link and initiate the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "lotes.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Box p={8}>
      <Helmet title="Lotes do empreendimento" />

      <Grid justify="space-between" container>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Lotes do empreendimento
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
              Início
            </Link>
            <Typography>Empreendimentos</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid justify="flex-end" container xs={6} alignItems="right">
          <Grid item>
            <Button
              variant="contained"
              color="danger"
              startIcon={<Icons.Delete />}
              onClick={async () => {
                await BpLots.clean(projectId);
                setData({ rows: [] });
                setPage(0);
              }}
              style={{ marginRight: 12 }}
            >
              Recarregar Lotes
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Icons.Share />}
              onClick={downloadCSV}
            >
              Exportar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div style={{ marginBottom: 16 }}>
            <Paper>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={"small"}
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      {Object.keys(summary).map((key) => (
                        <TableCell>{key.toLocaleUpperCase()}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {Object.keys(summary).map((key) => (
                        <TableCell>{summary[key]}</TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <div>
            <Paper>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size={"small"}
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>IDENTIFICAÇÃO DA UNIDADE</TableCell>
                      <TableCell>
                        STATUS
                        <br />
                        ÁREA REAL (M²)
                      </TableCell>
                      <TableCell>
                        VALOR FINAL DO LOTE
                        <br />
                        PREÇO FINAL DO LOTE (R$/M²)
                      </TableCell>
                      <TableCell>PRÉ-RESERVA</TableCell>
                      <TableCell>RESERVA</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.rows?.map((row, index) => {
                      return (
                        <TableRow
                          style={{
                            background: row.status.includes("VENDA")
                              ? "#f0fff0"
                              : "#ffeeed",
                          }}
                        >
                          <TableCell id={index} scope="row">
                            <Box>
                              <strong>{row.identifier}</strong>
                              <br />
                              <Typography>
                                Comercial - Frente | Avenida
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell id={index} scope="row">
                            <strong>{row.status}</strong>
                            <br />
                            <NumberFormat
                              value={row.area / 100}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale="2"
                              displayType="text"
                              fixedDecimalScale="true"
                            />
                          </TableCell>
                          <TableCell id={index} scope="row">
                            <strong>
                              <NumberFormat
                                value={row.finalPrice / 100}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale="2"
                                displayType="text"
                                fixedDecimalScale="true"
                                prefix="R$ "
                              />
                            </strong>
                            <br />
                            <NumberFormat
                              value={row.areaPrice / 100}
                              thousandSeparator="."
                              decimalSeparator=","
                              decimalScale="2"
                              displayType="text"
                              fixedDecimalScale="true"
                              prefix="R$ "
                            />
                          </TableCell>
                          <TableCell id={index} scope="row">
                            <strong>{row.preReservationStatus}</strong>
                            <br />

                            <a href={row.link} target="_blank" rel="noreferrer">
                              {row.preReservationCode}
                            </a>
                          </TableCell>
                          <TableCell id={index} scope="row">
                            <Box>{row.reservationCode}</Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[PageSize]}
                component="div"
                count={data.count || 0}
                rowsPerPage={PageSize}
                page={page}
                onChangePage={(_, page) => setPage(page)}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
