import { api } from "./BackendService";

export const create = (data) => api.post("/projects", data);
export const edit = (id, data) => api.put(`/projects/${id}`, data);
export const getById = (id) => api.get(`/projects/${id}`);
export const remove = (id) => api.delete(`/projects/${id}`);
export const list = (page, name) =>
  api.get("/projects", { params: { page: page, name: name } });
export const getMapsById = (id) => api.get(`/projects/${id}/maps`);

export const listLogs = (id, page) =>
  api.get(`/projects/${id}/roulette-logs`, { params: { page: page } });

export const listRoulettes = (id, page) =>
  api.get(`/projects/${id}/roulettes`, {
    params: { page: page, pageSize: 100 },
  });

export const approvePreReservation = (projectId, lotId) =>
  api.post(`projects/${projectId}/maps/${lotId}/approve`);
export const reprovePreReservation = (projectId, lotId, data) =>
  api.post(`projects/${projectId}/maps/${lotId}/reprove`, data);
export const nextWave = (projectId) =>
  api.post(`projects/${projectId}/maps/next-wave`);
