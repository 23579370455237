/* eslint-disable import/first */
import React from "react";

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import { SignOutPage } from "../pages/auth";
import SignIn from "../pages/auth/SignIn";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Protected routes
import {
  Business,
  Description,
  ExitToApp,
  Folder,
  Label,
  Message,
  People,
  Receipt,
  SupervisedUserCircle,
} from "@material-ui/icons";
import {
  AdminUsersFormPage,
  AdminEditPage,
  AdminUsersIndexPage,
  HomePage,
} from "../pages/platform";
import {
  CollaboratorsFormPage,
  CollaboratorsIndexPage,
  FormsIndexPage,
  FormsFormPage,
  FormEditPage,
  SubmissionsIndexPage,
} from "../pages/collaborators-portal";
import {
  BannersEditFormPage,
  BannersFormPage,
  BannersIndexPage,
  BrokersAccreditationsPage,
  BrokersEditFormPage,
  BrokersIndexPage,
  BrokersRouletteLogsPage,
  BrokersRoulettesPage,
  LotsBrokersIndexPage,
  LotsIndexPage,
  LotsTablePage,
  NotificationsFormPage,
  NotificationsIndexPage,
  ProjectsEditFormPage,
  ProjectsFormPage,
  ProjectsIndexPage,
  ProjectsMapPage,
  ProjectsRouletteLogsPage,
  ProjectsRouletteBrokersPage,
  RealStatesBrokersIndexPage,
  RealStatesEditFormPage,
  RealStatesFormPage,
  RealStatesIndexPage,
} from "../pages/brokers-portal";
import { BrokersRoulletesEditPage } from "../pages/brokers-portal/BrokersRoulettesEditPage";

// Routes using the Auth layout
export const authRoutes = [
  {
    path: "/auth/sign-in",
    component: SignIn,
  },
  {
    path: "/auth/sign-out",
    component: SignOutPage,
  },
  {
    path: "/auth/404",
    component: Page404,
  },
  {
    path: "/auth/500",
    component: Page500,
  },
];

// Routes that are protected
export const protectedRoutes = [
  {
    path: "/",
    component: HomePage,
    guard: AuthGuard,
  },
  {
    path: "/bp/realstates/new",
    component: RealStatesFormPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/realstates/:realstateId/edit",
    component: RealStatesEditFormPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/realstates/:realstateId/brokers",
    component: RealStatesBrokersIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/projects",
    component: ProjectsIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/projects/new",
    component: ProjectsFormPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/projects/:projectId/edit",
    component: ProjectsEditFormPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/projects/:projectId/roulette-logs",
    component: ProjectsRouletteLogsPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/projects/:projectId/roulette-brokers",
    component: ProjectsRouletteBrokersPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/brokers",
    component: BrokersIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/brokers/:brokerId/accreditations/:accreditationId",
    component: BrokersAccreditationsPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/brokers/:brokerId/roulettes",
    component: BrokersRoulettesPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/brokers/:brokerId/roulettes/:rouletteId",
    component: BrokersRoulletesEditPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/projects/:projectId/lots",
    component: LotsIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/projects/:projectId/lots-table",
    component: LotsTablePage,
    guard: AuthGuard,
  },
  {
    path: "/bp/brokers/:brokerId/lots",
    component: LotsBrokersIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/brokers/:brokerId/roulette-logs",
    component: BrokersRouletteLogsPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/brokers/:brokerId/edit",
    component: BrokersEditFormPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/realstates",
    component: RealStatesIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/banners",
    component: BannersIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/banners/new",
    component: BannersFormPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/banners/:bannerId/edit",
    component: BannersEditFormPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/notifications",
    component: NotificationsIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/bp/notifications/new",
    component: NotificationsFormPage,
    guard: AuthGuard,
  },
  {
    path: "/cp/forms",
    component: FormsIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/cp/forms/new",
    component: FormsFormPage,
    guard: AuthGuard,
  },
  {
    path: "/cp/forms/:formId/edit",
    component: FormEditPage,
    guard: AuthGuard,
  },
  {
    path: "/cp/collaborators",
    component: CollaboratorsIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/cp/collaborators/new",
    component: CollaboratorsFormPage,
    guard: AuthGuard,
  },
  {
    path: "/cp/collaborators/:collaboratorId/edit",
    component: CollaboratorsFormPage,
    guard: AuthGuard,
  },
  {
    path: "/cp/submissions",
    component: SubmissionsIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/platform/admins",
    component: AdminUsersIndexPage,
    guard: AuthGuard,
  },
  {
    path: "/platform/admins/new",
    component: AdminUsersFormPage,
    guard: AuthGuard,
  },
  {
    path: "/platform/:adminId/edit",
    component: AdminEditPage,
    guard: AuthGuard,
  },
];

export const protectedRoutesWithBlankLayout = [
  {
    path: "/bp/projects/:projectId/maps",
    component: ProjectsMapPage,
    guard: AuthGuard,
  },
];

// Routes visible in the sidebar
export const sidebarRoutes = [
  {
    id: "Formulários",
    header: "Portal do Colaborador",
    path: "/cp/forms",
    icon: <Description />,
  },
  {
    id: "Respostas",
    path: "/cp/submissions",
    icon: <Receipt />,
  },
  {
    id: "Colaboradores",
    path: "/cp/collaborators",
    icon: <People />,
  },
  {
    id: "Empreendimentos",
    header: "Portal do Corretor",
    path: "/bp/projects",
    icon: <Folder />,
  },
  {
    id: "Corretores",
    path: "/bp/brokers",
    icon: <People />,
  },
  {
    id: "Imobiliárias",
    path: "/bp/realstates",
    icon: <Business />,
  },
  {
    id: "Banners",
    path: "/bp/banners",
    icon: <Label />,
  },
  {
    id: "Notificações",
    path: "/bp/notifications",
    icon: <Message />,
  },
  {
    id: "Administradores",
    header: "Portal do Administrador",
    path: "/platform/admins",
    icon: <SupervisedUserCircle />,
  },
  {
    id: "Sair",
    path: "/auth/sign-out",
    icon: <ExitToApp />,
  },
];
