import { api } from "./BackendService";

export const create = (projectId, data) =>
  api.post(`/projects/${projectId}/lots`, data);
export const edit = (projectId, id, data) =>
  api.put(`/projects/${projectId}/lots/${id}`, data);
export const getById = (projectId, id) =>
  api.get(`/projects/${projectId}/lots/${id}`);
export const list = (projectId, page, pageSize = 6) =>
  api.get(`/projects/${projectId}/lots`, { params: { page: page, pageSize } });

export const clean = (projectId) => api.delete(`/projects/${projectId}/lots`);
